import { ILotteryTemplate, ILuZhuProps, ILuZhuTepmplateOption } from './type'

const DefaultLuZhuOptions: ILuZhuTepmplateOption['luZhuOptions'] = [
  { label: '大小', value: 'DX' },
  { label: '单双', value: 'DS' },
  { label: '龙虎', value: 'LH' },
]
/** 不同彩种，路珠的参数选项组，按模板分 */
export const LuZhuTemplateOptions: ILuZhuTepmplateOption[] = [
  /** PKS 模板的彩种 filter */
  {
    template: 'PKS',
    rankingOptions: [
      { label: '冠亚和', value: 'GY', prefix: true },
      { label: '冠军' },
      { label: '亚军' },
      { label: '三号' },
      { label: '四号' },
      { label: '五号' },
      { label: '六号' },
      { label: '七号' },
      { label: '八号' },
      { label: '九号' },
      { label: '十号' },
    ].map((item, index) => ({ ...item, value: item.value != undefined ? item.value : index })),
    luZhuOptions: DefaultLuZhuOptions,
  },
  /** SSC 模板的彩种 filter */
  /** 有问题，返回的数据 LH 没有对应的 */
  /** LH0 = LH */
  {
    template: 'SSC',
    rankingOptions: [
      { label: '总和', value: 'ZH', prefix: true, setLHIndex0AsEmpty: true }, // 没有 ZHLH ，只有 LH
      { label: '第一球' },
      { label: '第二球' },
      { label: '第三球' },
      { label: '第四球' },
      { label: '第五球' },
    ].map((item, index) => ({ ...item, value: item.value != undefined ? item.value : index })),
    luZhuOptions: DefaultLuZhuOptions,
  },
  /** KS 模板的彩种 filter */
  /** 没有龙虎 */
  {
    template: 'KS',
    rankingOptions: [{ label: '总和', value: 'ZH', prefix: true }].map((item, index) => ({
      ...item,
      value: item.value != undefined ? item.value : index,
    })),
    luZhuOptions: DefaultLuZhuOptions.filter((i) => i.value != 'LH'),
  },
  /** KLSF 模板的彩种 filter */
  {
    template: 'KLSF',
    rankingOptions: [
      { label: '总和', value: 'ZH', prefix: true, setLHIndex0AsEmpty: true }, // 没有 ZHLH ，只有 LH
      { label: '第一球' },
      { label: '第二球' },
      { label: '第三球' },
      { label: '第四球' },
      { label: '第五球' },
      { label: '第六球' },
      { label: '第七球' },
      { label: '第八球' },
    ].map((item, index) => ({ ...item, value: item.value != undefined ? item.value : index })),
    luZhuOptions: DefaultLuZhuOptions,
  },
  /** SYXW 模板的彩种 filter */
  {
    template: 'SYXW',
    rankingOptions: [
      { label: '总和', value: 'ZH', prefix: true }, // 没有 ZHLH ，只有 LH
      { label: '第一球' },
      { label: '第二球' },
      { label: '第三球' },
      { label: '第四球' },
      { label: '第五球' },
    ].map((item, index) => ({ ...item, value: item.value != undefined ? item.value : index })),
    luZhuOptions: DefaultLuZhuOptions,
  },
  /** KLC 模板的彩种 filter */
  /** 只有总和路珠 / 只有路珠 filter */
  {
    template: 'KLC',
    rankingOptions: [
      { label: '总和', value: 'ZH', prefix: true }, // 没有 ZHLH ，只有 LH
    ].map((item, index) => ({ ...item, value: item.value != undefined ? item.value : index })),
    luZhuOptions: [
      { label: '总和大小', value: 'DX' },
      { label: '总和单双', value: 'DS' },
      { label: '大小单双', value: 'DXDS', ifDependent: true }, // 这个在 kai8 没有，根据数据返回加上的
      { label: '总和五行', value: 'WX', ifDependent: true },
      { label: '前后和', value: 'QHH', ifDependent: true },
      { label: '单双和', value: 'DSH', ifDependent: true },
    ],
  },
]

/** 根据模板，得出LuZhu组件所需要的props */
export const mapLuzhuProps = (template: ILotteryTemplate) => {
  const luZhuOptions = LuZhuTemplateOptions.find((i) => i.template == template)?.luZhuOptions
  const rankingOptions = LuZhuTemplateOptions.find((i) => i.template == template)?.rankingOptions

  const options: (Pick<ILuZhuProps, 'rankingOptions' | 'luZhuOptions'> & {
    subSelection: string
  })[] = [
    { subSelection: 'ZH_LUZHU', rankingOptions, luZhuOptions },
    {
      subSelection: 'GYH_LUZHU',
      rankingOptions: rankingOptions?.filter((i) => i.value == 'GY'),
      luZhuOptions: luZhuOptions?.filter((i) => i.value != 'LH'), // 冠亚 没有龙虎
    },
    {
      subSelection: 'DX_LUZHU',
      rankingOptions: rankingOptions,
      luZhuOptions: luZhuOptions?.filter((i) => i.value == 'DX'),
    },
    {
      subSelection: 'DS_LUZHU',
      rankingOptions: rankingOptions,
      luZhuOptions: luZhuOptions?.filter((i) => i.value == 'DS'),
    },
    {
      subSelection: 'LH_LUZHU',
      rankingOptions: rankingOptions,
      luZhuOptions: luZhuOptions?.filter((i) => i.value == 'LH'),
    },
    { subSelection: 'ZONG_HE_LUZHU', rankingOptions, luZhuOptions },
  ]
  return options
}

/** LuZhu 相关的子菜单 */
export const LuZhuSubSelectionsList = [
  'ZH_LUZHU', // 综合
  'GYH_LUZHU', // 冠亚和
  'DX_LUZHU', // 大小
  'DS_LUZHU', // 单双
  'LH_LUZHU', // 龙虎
  'ZONG_HE_LUZHU', // 总和
]
