import demoActions from '../../../redux/actions/demoActions';
import { AppDispatch, RootState } from '../../../redux/store';
import { connect } from 'react-redux';

import './styles.scss';
import { apiService } from '../../../services';
import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import templates from '../../../constants/templates';
import { DatePicker, Select, Skeleton } from 'antd';
import type { DatePickerProps } from 'antd';
import dayjs from 'dayjs'
import Table from '../../../components/common/Table';
import { IKillPlanData, ILotteryItem, IPlanItem } from '../../../types';
import React from 'react';
import { RangePickerProps } from 'antd/es/date-picker';
import appActions from '../../../redux/actions/appActions';

import {header, introduce} from '../../../constants/utils/index';

const mapStateToProps = (state:RootState) => ({
  killPlanLotterys: state.lotteryReducers.killPlanLotterys,
});

const mapDispatchToProps = (dispatch:AppDispatch) => ({
  GetList: () => dispatch(demoActions.GetList()),
});
interface IProps{
  killPlanLotterys:ILotteryItem[], 
}

function Plan ({killPlanLotterys}:IProps){
  const { templateAndGame } = useAppSelector((state) => state.appReducers)
  const { companyCode } = useAppSelector((state) => state.appReducers)

  const dispatch = useAppDispatch();

  const [date, setDate] = useState(dayjs());
  const [count, setCount] = useState(10);
  const [killPlanData, setKillPlanData] = useState<IKillPlanData>();
  const [selectedTemplate, setSelectedTemplate] = useState<string>('');
  const [selectedChildMenu, setSelectedChildMenu] = useState<number>(1);
  const [gameImage, setGameImage] = useState();
  const [clockTimer, setClockTimer] = useState<string>(new Date().toString())
  const [killR,setKillR] = useState<Array<number>>([0, 0, 0, 0, 0]);
  const [total, setTotal] = useState(count);

  const [currentKillPlanLottery, setCurrentKillPlanLottery] = useState<{template:string, code:string}>();
  

  const getDifferenceTime = (current:string, target = killPlanData?.ntime)=>{
    const targetTime = dayjs(target).valueOf();//获取当前时间对应的毫秒数
    const currentTime = Date.parse(current);//获取八点对应的毫秒数    
    const differenceTime = Math.floor((targetTime - currentTime) / 1000);
    
    if(differenceTime <= 0){
      return differenceTime
    }else{
      const hours = Math.floor(differenceTime / 3600);
      const minutes = Math.floor((differenceTime - (hours * 3600)) / 60);
      const seconds = differenceTime - hours * 3600 - minutes * 60
      return `${hours.toString().padStart(2,"0")}:${minutes.toString().padStart(2,"0")}:${seconds.toString().padStart(2,"0")}`
    }   
  }  
  
  const killCalulate = (item:IPlanItem)=>{
    if(item){
      return item.planList[selectedChildMenu - 1].map(function (t,index) {
        let cls = 'killColortrue', bg = 'killColorBgtrue text-white', ktxt = '对';
        if(t === item.openResult[selectedChildMenu - 1]){
          (cls = 'killColorfalse', bg = 'text-black', ktxt = '错')
        }
        if(item.openResult.length === 1){
          (cls = 'opacity-50', bg = 'opacity-50', ktxt = '-')
        }
        return <React.Fragment key={index.toString()}>
          <div  className={`flex-1  flex justify-center items-center ${cls}`}>{`杀:${t}`}</div>
          <div  className={`flex-1  flex justify-center items-center ${bg}`}>{`${item.openResult.length === 1 ? '-' : '杀' + ktxt}`}</div>
        </React.Fragment>
      })  
    }
  }

  const getKillPlan = async () => {
    try {
      const res = await apiService.get(`/plan/KillPlan/${companyCode}/${currentKillPlanLottery?.code}/${date.format('YYYY-MM-DD')}/${count}`);
      setKillPlanData(res.data.data); 
    } catch (error) {
      setKillPlanData(undefined);
    }
  }

  const loadImage = (imageName:string) => {
    import(`../../../assets/image/${imageName}.png`).then(image => {        
      setGameImage(image.default)
    }).catch(()=>{
      setGameImage(undefined)
    });
  };

  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    setKillPlanData(undefined);
    setDate(dayjs(dateString))
  };

  const resultSummaryFunc = ()=>{
    return  header[`${currentKillPlanLottery?.template}`] ? header[`${currentKillPlanLottery?.template}`].map((item,index)=> 
      <div className='resultSummaryCell' key={index.toString()}>
        <div>{item}</div>
        <div>
          {
            total + '期对' + killR[index] + '期'
          }
        </div>
        <div>
          {
            Math.round( killR[index] / total * 10000) / 100 + '%'
          }
        </div>
      </div>
    ) : ''
  }

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current > dayjs().endOf('day');
  };  

  useEffect(()=>{
    if(killPlanLotterys?.length > 0 && templateAndGame){
      const temp = killPlanLotterys?.find(x=>x.code === templateAndGame?.code);
      if(temp){        
        setCurrentKillPlanLottery({...templateAndGame})
      }
      else{
        const _item = killPlanLotterys[0];
        setCurrentKillPlanLottery({template:_item.template,code:_item.code});
        dispatch(appActions.setTemplateAndCode({template:_item.template,code:_item.code}));
      }
      return ()=>setCurrentKillPlanLottery(undefined)
    }

  },[templateAndGame, killPlanLotterys])  

  useEffect(()=>{
    if(currentKillPlanLottery){
      getKillPlan();
      const _timerId = setInterval(
        (): void => {
          getKillPlan()
        },
        10000
      );
      const clockId = setInterval(
        (): void => {
          setClockTimer(new Date().toString()
          )
        },
        1000
      );
      return () => {
        clearInterval(clockId);
        clearInterval(_timerId);
      };
    }
  },[date,count, currentKillPlanLottery])

  useEffect(()=>{
    setKillPlanData(undefined);
    if(currentKillPlanLottery){
      loadImage(currentKillPlanLottery.code);
    }
  },[currentKillPlanLottery])

  useEffect(()=>{
    if(currentKillPlanLottery?.template && templates[currentKillPlanLottery.template]?.trendSubMenu){
      setSelectedTemplate(currentKillPlanLottery.template)
      setSelectedChildMenu((templates[currentKillPlanLottery.template]?.trendSubMenu as Array<{label:string;value:number}>)[0]?.value)
    }
  },[currentKillPlanLottery])

  useEffect(()=>{
    const _killR = [0,0,0,0,0];
    if(killPlanData && Object.keys(killPlanData).length > 0){
      const _total = killPlanData.list.length
      killPlanData.list.forEach((item,index)=>{
        if(index === 0 && item.openResult.length === 1){
          setTotal(_total - 1)
        }
        else{
          item.planList[selectedChildMenu - 1].forEach(function (t, j) {
            if(t !== item.openResult[selectedChildMenu - 1]){
              _killR[j] += 1;
            }
          })
        }
      })
    }
    setKillR(_killR)
  },[killPlanData?.kcount,selectedChildMenu])

  return ( 
    <div className='planWrapper'>
      <div className='latestKillPlan flex items-center'>
        {gameImage && <img className='homeGameImage' src={gameImage} alt="" srcSet="" /> }
        <div className='ml-[20px] flex-1'>
          <div className='flex text-white'>
            <div className=' mr-[20px] flex flex-col justify-between w-[150px]'>
              <div className=' text-[20px] font-bold'>
                {killPlanLotterys.find(x=>x.code === currentKillPlanLottery?.code)?.name ?? ''}
              </div>
              <div>
                {`${killPlanData?.list ? killPlanData.list[0].openTerm : '--'}期`}
              </div>
            </div>
            <div className=' mr-[20px] flex flex-col justify-between w-[200px]'>
              <div>最新杀号</div>
              <div className='flex'>
                {
                  templates[selectedTemplate] && templates[selectedTemplate].killPlanNumber && killPlanData?.list
                    ? 
                    (templates[selectedTemplate].killPlanNumber!)(killPlanData?.list[0].planList[selectedChildMenu - 1] ?? ['']).map((x,index)=> 
                      <div key={index.toString()} className={`${x.className} mr-2`}>{x.value}</div> 
                    )
                    : 
                    '--'
                }
              </div>
            </div>
            <div className=' mr-[20px] flex flex-col justify-between w-[72px]'>
              <div>已推荐</div>
              <div className='text-[20px]'>{`${killPlanData?.kcount ?? '--'}期`}</div>
            </div>
            <div className='mr-[20px]'>数据汇总</div>
            <div className='mr-[20px] flex flex-col justify-between'>
              <div>总成功概率</div>
              <div className='text-[20px]'>
                {
                  Math.round(killR.reduce((a,b)=>a + b) / 5 / total * 10000) / 100 + '%'
                }
              </div>
            </div>
            <div className='childMenu my-auto'>
              {
                templates[selectedTemplate] && templates[selectedTemplate].trendSubMenu 
                  ? templates[selectedTemplate].trendSubMenu?.find(x=>x.value === selectedChildMenu )?.label 
                  : ''
              }
            </div>
            <div className='flex-1  flex flex-col items-end justify-between'>
              <div className=' text-center'>开奖到计</div>
              <div className=' text-[20px]  font-bold text-center'>       
                {
                  typeof getDifferenceTime(clockTimer) === 'number' ?
                    <div className='flex'>生成杀号计划中 <div className="dots-1"></div></div>
                    :
                    getDifferenceTime(clockTimer)
                }
              </div>
            </div>

          </div>
          <div className='w-full border border-[#B2B2B2] bg-white mt-[30px]'>
            <div className='flex w-full latestHeader h-[40px]'>
              {header[`${currentKillPlanLottery?.template}`] && header[`${currentKillPlanLottery?.template}`].map((item, index)=> 
                <React.Fragment key={index.toString()}>
                  <div  className='flex-1 flex justify-center items-center'>{item}</div>
                  <div  className='flex-1 flex justify-center items-center'>成绩</div>
                </React.Fragment> 
              )}
            </div>
            <div className='flex  w-full latestHeader noBgColor h-[40px] border-t-[1px] border-t-[#B2B2B2] '>
              {killPlanData && killPlanData?.list
                ?
                killCalulate(killPlanData?.list.find(x=>x.openResult.length !== 1) as IPlanItem)
                : 
                <div className='w-full flex justify-center items-center opacity-60 px-[10%]'>
                  {
                    !killPlanData ? <Skeleton.Button active block /> : '无数据'
                  }
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <div className='dateAndCount flex items-center text-white'>
        <div>稳定杀号</div>
        <div className=' ml-auto'>选择日期</div>
        <DatePicker 
          disabledDate={disabledDate}
          className='mx-4'
          defaultValue={dayjs()}
          size='large'
          showToday={false}
          onChange={onChange}
        />
        <Select
          value={count}
          size='large'
          style={{ width: 120 }}
          onChange={(value:number)=>{setKillPlanData(undefined);setCount(value)}}
          options={[
            { value: 10, label: '近10期' },
            { value: 20, label: '近20期' },
            { value: 30, label: '近30期' },
            { value: 50, label: '近50期' },
            { value: 100, label: '近100期' },
          ]}
        />

      </div>
      <div className='killPlanListWrapper'>
        <div className='flex flex-wrap'>
          {
            templates[selectedTemplate] && templates[selectedTemplate].trendSubMenu?.map(item=> 
              <div 
                onClick={()=>setSelectedChildMenu(item.value)}
                className={`buttonWrapper ${selectedChildMenu === item.value ? 'selected' : ''}`}
                key={item.label}>
                {item.label}
              </div> )
          }
        </div>
        <div className='my-[40px]'>
          <Table
            key={`${selectedTemplate}`}
            header = {[
              {label:'开奖期号',className:'flex justify-center items-center h-full'},
              {label:'开奖结果',className:'flex justify-center items-center h-full'},
              ()=>{ 
                return <div className='killNumberCalculate flex w-full'>
                  {
                    header[`${currentKillPlanLottery?.template}`] ?
                      header[`${currentKillPlanLottery?.template}`].map((item, index)=> 
                        <React.Fragment key={`${item}-${index}`} >
                          <div className='flex-1 flex justify-center items-center'>
                            {item}
                          </div>
                          <div className='flex-1 flex justify-center items-center'>成绩</div>
                        </React.Fragment> 
                      )
                      : ''
                  }
                </div>
              }
            ]}
            contentProperty = {[
              item=> <div>{(item as IPlanItem).openTerm}</div>,
              item=> <div className='flex justify-center items-center w-full'>
                {(item as IPlanItem).openResult.length !== 1 ?
                  (templates[selectedTemplate].killPlanNumber!)((item as IPlanItem).openResult).map((child, index)=> <div key={`${index}`} className={`${child.className} mx-[3px]`}>{child.value}</div> )
                  :
                  <div className='flex opacity-50'>等待开奖</div>
                }
              </div>,
              item=> <div className='flex justify-center items-center w-full h-full killNumberCalculate'>
                {
                  killCalulate(item as IPlanItem)
                }
              </div>,
            ]
            }
            contentData = {killPlanData?.list || []}
            loading = {killPlanData ? false : true}
          />
        </div>

        <div className='w-full border border-[#B2B2B2] bg-white my-[30px] flex resultSummary'>
          <div className='resultSummaryCell'>
            <div>成绩统计</div>
            <div>杀对次数</div>
            <div>成功概率</div>
          </div>
          {
            resultSummaryFunc()
          }
        </div>
        {
          introduce[`${selectedTemplate}`] && introduce[`${selectedTemplate}`].map((item, index)=> 
            <div className='w-full mb-[20px] introduceCell' key={index}>
              <div>{item.title}</div>
              <div>{item.content}</div>
            </div> 
          )
        }
      </div>
    </div>
  );
}


export default connect(mapStateToProps, mapDispatchToProps)(Plan);
