import { IAboutUs } from "../../types";

export const category = new Map([['PKS','PKS系列'],['SSC','时时彩系列'],['KS','快3系列'],['SYXW','11选5系列'],['KLSF','快乐十分系列']]);

export const header:{[key:string]:Array<string>} = {
  'PKS':['冷雨夜','木木三','醉书生','司令爷','沐子眠'],
  'SSC':['忆离笙','值半钱','楼满风','沐雪柔','寒千落'],
  'KS':['天横仙','城坠纱','玉月穴','千天摇','月对佳'],
  'SYXW':['寒满江','西雨烟','红童云','钱横园','龙眠朝'],
  'KLSF':['频风横','芳渊坐','杨观赤','绿云李','李东明'],
}

export const introduce:{[key:string]:Array<{title:string,content:string}>} = {
  'PKS':[
    {   
      title:'功能介绍',
      content:'PK拾冠军杀号公式，依据PK拾历史开奖数据规律为依托，排除出现概率最小的PK拾号码，PK拾综合杀号公式是有效提高缩小PK拾投注范围的一种方法指导。'
    },
    {   
      title:'使用技巧',
      content:'PK拾冠军杀号公式公式A~E，选择几种成绩率高的PK拾冠军杀号公式的公式结果即可，并非全部冠军杀号公式都做为彩票投注依托。'
    }
  ],
  'SSC':[
    {   
      title:'功能介绍',
      content:'时时彩万位杀号杀号公式，依据时时彩历史开奖数据规律为依托，排除出现概率最小的时时彩号码，时时彩综合杀号公式是有效提高缩小时时彩投注范围的一种方法指导。'
    },
    {   
      title:'使用技巧',
      content:'时时彩万位杀号杀号公式公式A~E，选择几种成绩率高的时时彩万位杀号杀号公式的公式结果即可，并非全部万位杀号杀号公式都做为彩票投注依托。'
    }
  ],
  'KS':[
    {   
      title:'功能介绍',
      content:'快3杀号公式，依据快3历史开奖数据规律为依托，排除出现概率最小的快3号码，快3综合杀号公式是有效提高缩小快3投注范围的一种方法指导。'
    },
    {   
      title:'使用技巧',
      content:'快3杀号公式公式A~E，选择几种成绩率高的快3杀号公式的公式结果即可，并非全部杀号公式都做为彩票投注依托。'
    }
  ],
  'SYXW':[
    {   
      title:'功能介绍',
      content:'11X5万位杀号杀号公式，依据11X5历史开奖数据规律为依托，排除出现概率最小的11X5号码，11X5综合杀号公式是有效提高缩小11X5投注范围的一种方法指导。'
    },
    {   
      title:'使用技巧',
      content:'11X5万位杀号杀号公式公式A~E，选择几种成绩率高的11X5万位杀号杀号公式的公式结果即可，并非全部万位杀号杀号公式都做为彩票投注依托。'
    }
  ]
}

export const AboutUsMenu = [
  {value:'ABOUT_US',label:'关于我们'},
  {value:'FREE_DUTY',label:'免责声明'},
  {value:'BUSINESS_COOPERATION',label:'商务合作'}
]

export const AboutUsContent:{[key:string]:Array<IAboutUs>} = {
  ABOUT_US:[
    {index:'',
      content:{
        mainContent:'开奖网成立于2016年，致力于将产品销售至亚洲各地。以追求最佳品质，重视用户反馈的娱乐平台服务着称，紧靠亚洲各地的文化，不断的创新技术，构建亚洲的最大游戏供应商。',
        child:[
          {
            subIndex:'',
            subContent:''
          }
        ]
      }
    },
    {index:'',
      content:{
        mainContent:'开奖网提供多元的娱乐平台和游戏商品开发，无论是在网站系统、电子游戏、彩票类、移动娱乐、彩票数据分析等都有丰富多样的选择。',
        child:[
          {
            subIndex:'',
            subContent:''
          }
        ]
      }
    },
    {index:'',
      content:{
        mainContent:'开奖网的愿景：最受欢迎的娱乐互联网企业科技的使命：通过互联网服务提升娱乐生活新品质科技肩负着重要的使命，美好的愿景，不断的努力，提供科技化的人性服务，开拓市场新领土。',
        child:[
          {
            subIndex:'',
            subContent:''
          }
        ]
      }
    },
    {index:'',
      content:{
        mainContent:'公司一直持续的进行市场资讯的收集和研究，持续拓展业务和开拓全新的服务领域，加强发展技术，至力于新产品的开发、合作。我们每一项产品和软件设计思念，都要求最简单最实用最方便，所以大大的满足用户和家户的娱乐要求，我们不断的为目标市场创造机会和话题，将新产品推向我们的合作伙伴、用户，创造双赢、多赢的局面。',
        child:[
          {
            subIndex:'',
            subContent:''
          }
        ]
      }
    }
  ],
  FREE_DUTY:[
    {index:'(1)',
      content:{
        mainContent:'开奖网对于任何包含、经由或连接、下载或从任何与有关我站服务所获得的任何内容、信息或广告，不声明或保证其正确性或可靠性； 并且对于用户经我站服务上的广告、展示而购买、取得的任何产品、信息或资料，开奖吧不负保证责任。用户自行负担使用我站服务 风险。',
        child:[
          {
            subIndex:'',
            subContent:''
          }
        ]
      }
    },
    {index:'(2)',
      content:{
        mainContent:'有权但无义务，改善或更正我站服务任何部分之任何疏漏、错误。',
        child:[
          {
            subIndex:'',
            subContent:''
          }
        ]
      }
    },
    {index:'(3)',
      content:{
        mainContent:'不保证（包括但不限于）：',
        child:[
          {
            subIndex:'(A)',
            subContent:'我站服务适合用户的使用要求；'
          },
          {
            subIndex:'(B)',
            subContent:'我站服务不受干扰，及时、安全、可靠或不出现错误；'
          },
          {
            subIndex:'(C)',
            subContent:'用户经由我站服务取得的任何产品、服务或其他材料符合用户的期望；'
          }
        ]
      }
    },
    {index:'(4)',
      content:{
        mainContent:'用户使用经由我站服务下载的或取得的任何资料，其风险自行负担；因该使用而导致用户电脑系统损坏或资料流失，用户应负完全责任；',
        child:[
          {
            subIndex:'',
            subContent:''
          }
        ]
      }
    },
    {index:'(5)',
      content:{
        mainContent:'基于以下原因而造成的利润、商业信誉、资料损失或其他有形或无形损失，彩世界不承担任何直接、间接、附带、衍生或惩罚性的赔 偿：',
        child:[
          {
            subIndex:'(A)',
            subContent:'我站服务使用或无法使用；'
          },
          {
            subIndex:'(B)',
            subContent:'经由我站链接的其他网站购买或取得的任何产品、资料或服务；'
          },
          {
            subIndex:'(C)',
            subContent:'用户资料遭到未授权的使用或修改；'
          },
          {
            subIndex:'(D)',
            subContent:'其他与我站服务相关的事宜。'
          }
        ]
      }
    }

  ],
  BUSINESS_COOPERATION:[
    {index:'',
      content:{
        mainContent:'彩票开奖网是最快最全彩种的彩票开奖网站和免费彩票开奖数据提供商，为广大彩民提供全国各大联销型彩票及各地地方彩票的最快开奖结果、彩票资讯、彩票分析、彩票软件、彩票计划等全方位、一体化的综合彩票服务。',
        child:[
          {
            subIndex:'',
            subContent:''
          }
        ]
      }
    },
    {index:'',
      content:{
        mainContent:'彩票开奖网可以根据合作网站的需求，提供各种合作模式。',
        child:[
          {
            subIndex:'',
            subContent:''
          }
        ]
      }
    },
    {index:'',
      content:{
        mainContent:'诚招各大网站携手合作，互利共赢、共创美好生活。',
        child:[
          {
            subIndex:'',
            subContent:''
          }
        ]
      }
    }
  ],
}

export const _sum = (arr:string[], startIndex:number,endIndex:number)=>{
  return arr.slice(startIndex,endIndex).map(x=>Number(x)).reduce((a,b)=>a + b,0);
}
