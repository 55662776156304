/* eslint-disable @typescript-eslint/no-explicit-any */
import demoActions from '../../../redux/actions/demoActions';
import { AppDispatch, RootState } from '../../../redux/store';
import { connect } from 'react-redux';

import './styles.scss';

import templates from '../../../constants/templates';
import { useEffect, useState } from 'react';
import { apiService } from '../../../services';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { Line  } from 'react-chartjs-2';
import type { ScriptableTooltipContext } from 'chart.js';
import { ILatestItem, ILotteryList } from '../../../types';
import Table from '../../common/Table';
import dayjs from 'dayjs';
import { DatePicker, Pagination } from 'antd';
import type { DatePickerProps } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import ExternalTooltipHandler from '../../common/ExternalTooltipHandler';
import { motion } from 'framer-motion';
import { format } from 'date-fns';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

interface toolTipProp{
  x:number,
  y:number,
  item:ILatestItem
}

const mapStateToProps = (state:RootState) => ({
  lotteryList: state.lotteryReducers.lotteryList,
  templateAndGame:state.appReducers.templateAndGame,
  companyCode:state.appReducers.companyCode,
});

const mapDispatchToProps = (dispatch:AppDispatch) => ({
  GetList: () => dispatch(demoActions.GetList()),
});

interface IProps{
  lotteryList:ILotteryList,
  templateAndGame:{template:string; code:string}
  companyCode:string
}

interface ILiangMian{
  da:number[],
  xiao:number[],
  dan:number[],
  shuang:number[]
}

function ChannelHome ({lotteryList, templateAndGame, companyCode}:IProps){
  const [historyData,setHistoryData] = useState<ILatestItem[]>();
  const [trendData, setTrendData] = useState<{datasets:Array<{data:Array<{x:number | string,y:number}>}>}>({datasets:[]});
  const [selectedTemplate, setSelectedTemplate] = useState(templateAndGame.template);
  const [date, setDate] = useState(dayjs());
  const [isRndNum, setIsRndNum] = useState(0);

  const [subListStatus, setSubListStatus] = useState([false, false, false]);
  const [changLongData, setChangLongData] = useState<Array<{location:string,count:number}>>([]);
  const [liangMianData, setLiangMianData] = useState<ILiangMian>();

  const [tableSelectedIndex, setTableSelectedIndex] = useState('0');

  const _pageSize = 30;
  const [paginationProps, setPaginationProps] = useState({page:1,pageSize:_pageSize})
  const [total, setTotal] = useState(0);

  const { lotterys = [] } = lotteryList;

  const CustomTooltip = (obj:toolTipProp) => {      
    const type = templateAndGame.template === 'PKS' ? '冠亚和' : '总和'
    return (
      <div className="p-2">
        <div className='flex mb-2 justify-center whitespace-nowrap'>
          {`${type} ${obj.x}期 和值为： ${obj.y}`}
        </div>
        <div className={`flex ${templates[`${selectedTemplate}`].templateWrapper}`}>
          {templates[`${selectedTemplate}`].GenerateNumber(obj.item).map((item:{value:string | number,className:string,imageURL?:string}, index:number)=> {
            return item?.imageURL ? <img  key={`${item.value}_${index}`} className={`${item.className}`} src={`${item.imageURL}`} alt="" srcSet="" /> : <div className={`${item.className}`} key={`${item.value}_${index}`}>{item.value}</div>
          }
          ) }
          {isRndNum === 1 && <div className='flex justify-center items-center font-bold'>+{obj.item.rnd}</div>}
        </div>
      </div>
    );  
  };

  const options = {
    maintainAspectRatio:false,
    layout: {
      padding:0
    },
    elements:{
      line:{
        backgroundColor: '#FFC400',
        borderColor: '#FFC400',
      },
      point:{
        pointStyle: 'circle',
        pointRadius: 4,
        backgroundColor:'#FFC400',
      }
    },
    scales: {
      x: {
        ticks: {
          display: false
        }
      }
    },
    parsing:{
      xAxisKey: 'x',
      yAxisKey: 'y'
    },
    plugins: {
      tooltip: {
        enabled: false,
        external: (context:ScriptableTooltipContext<'line'>)=>ExternalTooltipHandler<'line', toolTipProp>(context, CustomTooltip),
      },
      legend:{
        display:false
      }
    }
  };

  const getInfo = async ({
    lotteryCode,
    page = 1,
    pageSize = _pageSize,
    date,
    flag
  }: {
    lotteryCode:string;
    page?:number;
    pageSize?:number;
    date:string,
    flag:boolean
  }) => {
    try {
      let res;
      if(flag){
        res = await apiService.get(`/lottery/history_gpc/${companyCode}/${lotteryCode}/${date}/${page}/${pageSize}`)
      }else{
        res = await apiService.get(`/lottery/history_dpc/${companyCode}/${lotteryCode}/${date}/${page}/${pageSize}`)
      }
      if(res?.data?.data){
        const data = res.data.data?.list ?? [];
        setTotal(res.data.data.recordCount)
        const temp = data.slice(0,20).map((item:{draw_code:string,issue:string})=> {
          const arr = item.draw_code.split(',').map(x=>Number(x));
          return ({
            y: templateAndGame.template === 'PKS' ? (arr[0] + arr[1]) : arr.reduce((a,b)=>a + b,0),
            x:String(item.issue),
            item
          })
        } 
        )
        setTrendData({datasets:[{ data:temp}]})    
        setHistoryData(data)    
      }  
    } catch (error) {
      // console.log(error)
    }
  }

  useEffect(()=>{
    const obj = {page:1,pageSize:_pageSize};
    setPaginationProps({...obj})
  },[templateAndGame,date])

  useEffect(()=>{
    if(templateAndGame?.code){   
      setIsRndNum(lotterys.find(x=>x.code === templateAndGame.code)?.isRndNum ?? 0)   
      setHistoryData(undefined)
      setSelectedTemplate(templateAndGame.template)
      const flag = lotterys.find(x=> x.code === templateAndGame.code)?.group === 1000 ? true : false
      const obj = {
        lotteryCode:templateAndGame.code,
        date : flag ? date.format('YYYY-MM-DD') : date.format('YYYY'),
        flag,
        ...paginationProps
      }
      getInfo(obj)
    }
  },[paginationProps])  

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current > dayjs().endOf('day');
  };

  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    setHistoryData(undefined);
    setDate(dayjs(dateString))
  };

  const onSubListClick = (index:number)=>{
    setSubListStatus(list=> list.map((bool, key)=>(index === key ? !bool : bool)))
  }

  const variants = {
    open: {  height: "auto"  },
    closed: {  height: 0},
  }

  useEffect(()=>{
    setDate(dayjs());
    let timerIndex = 0;
    setSubListStatus([false, false, false]);
    setChangLongData([]);
    setLiangMianData(undefined);
    const getChangLong = async () => {
      if(['PKS','SSC','KS'].includes(templateAndGame?.template)){
        const res = await apiService.get(`/changlong/${companyCode}/${templateAndGame?.code}/0`);
        setChangLongData(res.data?.data || []) 
      }  
    }
    const getLiangMian = async () => {
      if(['PKS','SSC'].includes(templateAndGame?.template)){
        const res = await apiService.get(`/liangmian/${companyCode}/${templateAndGame?.code}/${format(new Date(),'yyyy-MM-dd')}`);
        setLiangMianData(res.data?.data || [])   
      }
    }
    getChangLong();
    getLiangMian()
    timerIndex = window.setInterval(()=>{
      getChangLong();
      getLiangMian();
    },15000)
    return ()=>{
      clearInterval(timerIndex)
    }
  },[templateAndGame])
  
  
  return ( 
    <div className='ChannelHomeWrapper'>
      <div className='mb-2 flex items-center'>
        <div className='text-[24px] font-bold'>开奖记录</div>
        <div className='ml-auto  flex items-center justify-end'>
          {['PKS','SSC'].includes(selectedTemplate) && <div className={`buttonWrapper ${subListStatus[0] ? 'selected' : ''}`} onClick={()=>onSubListClick(0)}>今日双面/号码统计</div>}
          {['PKS','SSC','KS'].includes(selectedTemplate) && <div className={`buttonWrapper ${subListStatus[1] ? 'selected' : ''}`} onClick={()=>onSubListClick(1)}>长龙提醒</div>}
        </div>
      </div>
      {
        ['PKS','SSC'].includes(selectedTemplate) && templates[selectedTemplate] && templates[selectedTemplate].H_V_NumberTrend ? 
          <motion.div
            initial={{ height:0, overflow:'hidden' }}
            animate={subListStatus[0] ? "open" : "closed"}
            variants={variants}
          >
            <div className=' font-bold'>今日双面统计</div>
            <div className=' w-full flex text-[14px] my-[10px] liangMianWrapper'>
              <div className=' whitespace-nowrap' >
                <div>名次</div>
                <div>单双大小</div>
                <div>出现次数</div>
              </div>
              {
                templates[selectedTemplate].H_V_NumberTrend?.map((item,index)=> <div className='flex-1' key={item.value}>
                  <div>{item?.rank || item?.label}</div>
                  <div className='flex'>
                    {
                      ['大','小','单','双'].map(item => <div className='flex-1' key={item}>{item}</div> )
                    }
                  </div>
                  <div className='flex'>
                    {
                      ['da','xiao','dan','shuang'].map((item)=> <div className='flex-1' key={item}>{liangMianData ? liangMianData[item as keyof ILiangMian][index] ?? '' : ''}</div> )
                    }
                  </div>
                </div> )
              }
            </div>
          </motion.div>   
          : ''
      }
      {['PKS','SSC','KS'].includes(selectedTemplate) &&
      <motion.div
        initial={{ height:0, overflow:'hidden' }}
        animate={subListStatus[1] ? "open" : "closed"}
        variants={variants}
      >
        <div className=' font-bold'>长龙连开提醒</div>
        <div className=' w-full flex flex-wrap'>
          {
            changLongData.map(item=> <div className='changLongItem' key={item.location}>{`${item.location} ${item.count} 期`}</div> )
          }
        </div>
      </motion.div>
      }
      { !templateAndGame.code.includes('_FT') && lotterys.find(x=> x.code === templateAndGame.code)?.group === 1000  &&
        <div className=' w-full relative flex justify-center h-[400px]'>
          {trendData.datasets?.length > 0 &&
            <Line options={options} data={trendData} />
          }
        </div>
      }
      {
        templateAndGame?.code && lotterys?.length > 0 && 
        <div className='my-[40px]'>
          { templateAndGame.code.includes('_FT') || lotterys.find(x=> x.code === templateAndGame.code)?.group === 1000 ?
            <div className=' flex items-center my-[18px]'>
              <div className=' flex'>
                {[
                  { label: '今天', value: dayjs() },
                  { label: '昨天', value: dayjs().subtract(1, 'day') },
                  { label: '前天', value: dayjs().subtract(2, 'day')},
                ].map((item) => {
                  return (
                    <div
                      onClick={() => setDate(item.value)}
                      className={` buttonWrapper mr-[16px] ${
                        (item.value.format('YYYY-MM-DD') === date.format('YYYY-MM-DD') && 'selected') || ''
                      }`}
                      key={item.label}
                    >
                      {item.label}
                    </div>
                  )
                })}
              </div>
              <DatePicker
                allowClear={false}
                disabledDate={disabledDate}
                className='h-[39px]'
                defaultValue={dayjs()}
                value={date}
                showToday={false}
                onChange={onChange}
              />
            </div>
            :
            <div className=' flex items-center my-[18px]'>
              <div className=' flex'>
                {[
                  { label: '今年', value: dayjs() },
                  { label: '去年', value: dayjs().subtract(1, 'year') },
                  { label: '前年', value: dayjs().subtract(2, 'year')},
                ].map((item) => {
                  return (
                    <div
                      onClick={() => setDate(item.value)}
                      className={` buttonWrapper mr-[16px] ${
                        (item.value.format('YYYY') === date.format('YYYY') && 'selected') || ''
                      }`}
                      key={item.label}
                    >
                      {item.label}
                    </div>
                  )
                })}
              </div>
              <DatePicker
                picker='year'
                allowClear={false}
                disabledDate={disabledDate}
                className='h-[39px]'
                defaultValue={dayjs()}
                value={date}
                onChange={onChange}
              />
            </div>
          }
          <Table  
            className={`${selectedTemplate}TableWrapper`}     
            key={`${selectedTemplate}-${tableSelectedIndex}`}
            header = {[
              ()=><div className='flex justify-center items-center h-full'>开奖时间</div>,
              ()=><div className='flex justify-center items-center h-full'>期数</div>
            ].concat(
              templates[selectedTemplate].ChannelDrawResult.web.header.slice(0,1).map((item,itemIndex)=>
                (item.length === 1 
                  ?  
                  ()=> <div className='flex items-center justify-center h-full w-full' key={itemIndex.toString()}>{item.map((sub,index)=> <div key={index.toString()}>{sub.label}</div> )}</div> 
                  : 
                  ()=> <div className='flex items-center justify-center h-full w-full' key={itemIndex.toString()}>
                    {
                      item.map((sub,index)=> <div onClick={()=>setTableSelectedIndex(sub.value)} className={`buttonWrapper ${tableSelectedIndex === sub.value ? 'selected' : ''}`} key={index.toString()}>{sub.label}</div> )
                    }
                  </div> 
                )
              ),
              isRndNum === 1 ? ()=> <div className='flex justify-center items-center h-full'>挂码</div> : [],
              templates[selectedTemplate].ChannelDrawResult.web.header.slice(1).map((item,itemIndex)=>
                (item.length === 1 
                  ?  
                  ()=> <div className='flex items-center justify-center h-full w-full' key={itemIndex.toString()}>{item.map((sub,index)=> <div key={index.toString()}>{sub.label}</div> )}</div> 
                  : 
                  ()=> <div className='flex items-center justify-center h-full w-full' key={itemIndex.toString()}>
                    {
                      item.map((sub,index)=> <div onClick={()=>setTableSelectedIndex(sub.value)} className={`buttonWrapper ${tableSelectedIndex === sub.value ? 'selected' : ''}`} key={index.toString()}>{sub.label}</div> )
                    }
                  </div> 
                )
              ),
            )
            }
            contentProperty = {[
              (item:any)=> <div>{(item as ILatestItem).draw_time}</div>,
              (item:any)=> <div className='flex justify-center items-center w-full'>
                {(item as ILatestItem).issue}
              </div>,
            ].concat(
              templates[selectedTemplate].ChannelDrawResult.web.rowProperty.slice(0,1).map(
                func=> (item)=>func(item,tableSelectedIndex) ),
              isRndNum === 1 ? item=> <div>{(item as ILatestItem).rnd}</div> : [],
              templates[selectedTemplate].ChannelDrawResult.web.rowProperty.slice(1).map(
                func=> (item)=>func(item,tableSelectedIndex) ),
            )
            }
            contentData = {historyData ?? []}
            loading = {historyData ? false : true}
          />
          <div className='w-full flex justify-center pt-[20px]'>
            <Pagination onChange={(page)=>setPaginationProps({page,pageSize:_pageSize})} current={paginationProps.page} total={total} pageSize={_pageSize} pageSizeOptions={[_pageSize]} />
          </div>
        </div>
      }
    </div>
  );
}


export default connect(mapStateToProps, mapDispatchToProps)(ChannelHome);
